import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import Login, { LoginProps } from 'components/commercetools-ui/organisms/authentication/login';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic/hooks';

export interface Props {
  data: LoginProps;
}

export enum SUCCESS_MESSAGE_TYPE {
  CHANGE_PASSWORD_NOTIFICATION = 'cpn',
}

const AccountLoginTastic: React.FC<Props> = ({ data }) => {
  const router = useRouter();

  if (router.query.cpn) {
    data.successMessage = { type: SUCCESS_MESSAGE_TYPE.CHANGE_PASSWORD_NOTIFICATION };
  }

  const { loggedIn } = useAccount();

  if (loggedIn) return <Redirect target={'/'} />;

  return <Login {...data} />;
};

export default AccountLoginTastic;
